import React from 'react'

import {
  getHeaderFooterData,
  getOptions,
} from 'services/API'

import { normalizeMetadata, } from 'services/API/helpers'

import NotFoundSection from 'sections/NotFound'

export const getStaticProps = async ({ locale }) => {
  const [headerFooterData, options] = await Promise.all([
    getHeaderFooterData({ locale }),
    getOptions({ locale })
  ])

  const data = {
    title: '404',
    content: options.ErrorPage404Message || '',
    ctaTitle: options.ErrorPage404CtaLabel || '',
  }

  const metadata = await normalizeMetadata(data, options)

  return {
    props: {
      data,
      additionalData: { page: { pageDarkTheme: false } },
      options,
      menus: {
        header: headerFooterData.headerMenu || null,
        footer: headerFooterData.footerData || null,
      },
      metadata
    }
  }
}

export default function NotFound({ data }) {
  return (
    <NotFoundSection {...data} />
  )
}
